@import "../../index.scss";

$orangeColor : #F08E48;
$normalGray : #F9F5EB;




.landing {
    display: flex;
    flex-direction: column;

    .header-control {
        display: flex;
        flex-direction: column;

        .img-game-room {
            width: 40%;
            height: auto;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
            margin-top: 60px;

            @include media-breakpoint-down(md){
                width: 90%;
                margin-top: 60px;
            }
        }
    }

    .grid-game-control {
        margin-top: 120px;
        display: grid;
        width: auto;
        grid-template-columns: 33% 33% 33%;

        @include media-breakpoint-down(xl){
            grid-template-columns: 50% 50%;
        }

        @include media-breakpoint-down(md){
            grid-template-columns: 100%;
        }
    }

    .grid-game-2-control {
        margin-top: 80px;
        width: auto;
        display: grid;
        grid-template-columns: 50% 50%;

        
    }

    .item-control {
        width: 350px;
        margin-left: 16px;
        margin-right: 16px;
        display: flex;

        .logo-game {
            display: flex;
            position: absolute;
            margin-left: 24px;
            background: rgba(255, 255, 255, 0.2);
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
            backdrop-filter: blur(22.5px);
            height: 120px;
            width: 120px;
            margin-top: -50px;
            border-radius: 24px;
            z-index: 2;

            .logo-img {
                width: 80px;
                height: 80px;
                object-fit: contain;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .item-desc-control {
            width: auto;
            padding-top: 80px;
            padding-left: 17px;
            padding-right: 17px;
            padding-bottom: 24px;
            background: rgba(255, 255, 255, 0.2);
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
            backdrop-filter: blur(22.5px);
            border-radius: 24px;
            display: flex;
            flex-direction: column;

            .header-text {
                text-align: left;
                font-size: 36px;
                color: white;
                font-family: "Nunito-Bold";
            }

            .desc-text {
                font-size: 16px;
                font-family: "Nunito-Light";
                text-align: left;
                color: white;
                margin-bottom: 40px;
            }

            .button-control {
                top: 0;
                margin-top: auto;
                display: grid;
                grid-template-columns: 50% 50%;
            }

            .button-play {
                padding: 9px;
                width: 95%;
                height: 48px;
                background: $yellow;
                border: 3px solid #f9f5eb;
                border-radius: 8px;
                margin-top: auto;

                font-family: "Nunito-Bold";
                color: black;
                font-size: 17px;
            }

            .button-leaderboard {
                cursor: pointer;
                margin: auto;
                font-size: 17px;
                text-decoration: underline;
                font-family: "Poppins-Bold";
                color: white;
            }
        }

        @include media-breakpoint-down(xl){
            width: 90%;
            margin-bottom: 80px;
        }

        @include media-breakpoint-down(md){
            width: 90%;
            margin-bottom: 80px;
        }
    }

    .divider-line {
        margin-top: 60px;
        margin-bottom: 60px;
        width: 100%;
        height: 2px;
        background-color: white;
    }

    .challenging-control {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .next-challening-control{
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
      

        .img-next{
            cursor: pointer;
            width: 90px;
            height: 90px;
            object-fit: contain;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .challenging-zone-text {
        font-size: 40px;
        color: white;
        font-family: "Nunito-Bold";
        text-align: center;
    }

    .next-challenging-control{
        display: flex;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;

        .button-next{
            font-size: 25px;
            color: white;
            text-decoration: underline;
            font-family: 'Nunito-Bold';
            margin-left: 24px;
            margin-right: 24px;
        }
    }

    .loading-control{
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        .loading-img{
            width: 340px;
            height: auto;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        .loading-text{
            font-size: 22px;
            font-family: 'Nunito-Bold';
            color: white;
        }
    }

    .coming-soon-control{
        margin-top: 30px;
        height: 400px;
        display: flex;
        flex-direction: column;

        .coming-soon-img{
            width: 340px;
            height: auto;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        .coming-soon-text{
            font-size: 22px;
            font-family: 'Nunito-Bold';
            color: white;
        }
    }
    .game-img-top{
        width: 150px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
        object-fit: contain;
    }
    .leader-board-control {
        margin-top: 100px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: inline-block;

        .item-control {
            margin-bottom: 40px;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
            display: block;

            .logo-game {
                display: flex;
                position: absolute;
                margin-left: 24px;
                background: rgba(255, 255, 255, 0.2);
                box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
                backdrop-filter: blur(22.5px);
                height: 120px;
                width: 120px;
                margin-top: -50px;
                border-radius: 24px;
                z-index: 2;

                .logo-img {
                    width: 80px;
                    height: 80px;
                    object-fit: contain;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
          
            .game-img{
                width: 190px;
                height: auto;
                margin-left: auto;
                margin-right: auto;
                object-fit: contain;
            }

            .item-desc-control {
                width: auto;
                padding-top: 80px;
                padding-left: 30px;
                padding-right: 30px;
                padding-bottom: 24px;
                background: rgba(255, 255, 255, 0.2);
                box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
                backdrop-filter: blur(22.5px);
                border-radius: 24px;
                display: flex;
                flex-direction: column;

                .header-text-control{
                    
                    display: grid;
                    grid-template-columns: 50% 50%;

                    @include media-breakpoint-down(md){
                        grid-template-columns: 100%;
                    }
                }

                .text-control{
                    margin-top: auto;
                    margin-bottom: auto;
                }

                .header-text {
                    text-align: left;
                    font-size: 36px;
                    color: white;
                    font-family: "Nunito-Bold";
                }

                .desc-text {
                    font-size: 16px;
                    font-family: "Nunito-Light";
                    text-align: left;
                    color: white;
                    margin-bottom: 40px;
                }

                .text-leader-board {
                    text-align: center;
                    margin-top: 40px;
                    font-size: 28px;
                    color: white;
                    font-family: "Nunito-Bold";
                    margin-bottom: 24px;
                }

                .item-rank {
                    display: grid;
                    grid-template-columns: 30% 70%;
                    width: 100%;
                    height: 72px;
                    margin-bottom: 8px;
                    background: rgba(255, 255, 255, 0.2);
                    box-shadow: 0px 8px 14px rgba(47, 50, 71, 0.05);
                    backdrop-filter: blur(22.5px);
                    /* Note: backdrop-filter has minimal browser support */
                    border-radius: 12px;
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-top: 10px;
                    padding-bottom: 10px;

                    .text-rank-1st {
                        font-size: 32px;
                        color: $yellow;
                        text-align: left;
                        font-family: 'Nunito-Bold';
                        margin-top: auto;
                        margin-bottom: auto;

                        @include media-breakpoint-down(md){
                            font-size: 16px;
                        }
                    }

                    .text-point-1st {
                        font-size: 32px;
                        color: $yellow;
                        text-align: right;
                        font-family: 'Nunito-Bold';
                        margin-top: auto;
                        margin-bottom: auto;
                        @include media-breakpoint-down(md){
                            font-size: 16px;
                        }
                    }

                    .text-rank-2nd {
                        font-size: 28px;
                        color: #D3DAE8;
                        text-align: left;
                        font-family: 'Nunito-Bold';
                        margin-top: auto;
                        margin-bottom: auto;
                        @include media-breakpoint-down(md){
                            font-size: 16px;
                        }
                    }

                    .text-point-2nd {
                        font-size: 28px;
                        color: #D3DAE8;
                        text-align: right;
                        font-family: 'Nunito-Bold';
                        margin-top: auto;
                        margin-bottom: auto;
                        @include media-breakpoint-down(md){
                            font-size: 16px;
                        }
                    }

                    .text-rank-3rd {
                        font-size: 24px;
                        color: $orangeColor;
                        text-align: left;
                        font-family: 'Nunito-Bold';
                        margin-top: auto;
                        margin-bottom: auto;
                        @include media-breakpoint-down(md){
                            font-size: 16px;
                        }
                    }

                    .text-point-3rd {
                        font-size: 24px;
                        color: $orangeColor;
                        text-align: right;
                        font-family: 'Nunito-Bold';
                        margin-top: auto;
                        margin-bottom: auto;
                        @include media-breakpoint-down(md){
                            font-size: 16px;
                        }
                    }

                    .text-rank {
                        font-size: 20px;
                        color: $normalGray;
                        text-align: left;
                        font-family: 'Nunito-Bold';
                        margin-top: auto;
                        margin-bottom: auto;
                        @include media-breakpoint-down(md){
                            font-size: 16px;
                        }
                    }

                    .text-point {
                        font-size: 20px;
                        color: $normalGray;
                        text-align: right;
                        font-family: 'Nunito-Bold';
                        margin-top: auto;
                        margin-bottom: auto;
                        @include media-breakpoint-down(md){
                            font-size: 16px;
                        }
                    }
                }
            }

            @include media-breakpoint-down(md){
                width: 90%;
            }
        }
    }

    .city-img {
        background: url(../../assets/images/city.png);
        width: 100%;
        background-repeat: repeat-x;
        background-size: contain;
        height: 160px;
        margin-top: auto;
        bottom: 0;
        margin-top: 150px;
    }
}

.carousel-control-next-icon{
    width: 60px !important;
    height: 60px !important;
    @include media-breakpoint-down(md){
        width: 30px !important;
    height: 30px !important;
    }
}

.carousel-control-prev-icon{
    width: 60px !important;
    height: 60px !important;
    @include media-breakpoint-down(md){
        width: 30px !important;
    height: 30px !important;
    }
}

.carousel-control-next{
    @include media-breakpoint-down(md){
        margin-right: -7% !important;
    }
    
}
.carousel-control-prev{
    @include media-breakpoint-down(md){
        margin-left: -7% !important;
    }
}