@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


$fontBold: "Poppins-Bold";
$fontRegular: "Poppins-Regular";
$fontMedium: "Poppins-Medium";
$fontLight: "Poppins-Light";
$fontItalic: "Poppins-Italic";

$NunitoExtraBold: "Nunito-ExtraBold";
$NunitoBold: "Nunito-Bold";
$NunitoMedium: "Nunito-Medium";
$NunitoLight: "Nunito-Light";
$NunitoSemiBold: "Nunito-SemiBold";
$NunitoRegular: "Nunito-Regular";

$NanumRegular: "NanumPenScript-Regular";
@font-face {
    font-family: $NanumRegular;
    src: local("NanumPenScript-Regular"), url(../public/fonts/NanumPenScript-Regular.ttf) format("truetype");
}
@font-face {
    font-family: $NunitoExtraBold;
    src: local("Nunito-ExtraBold"), url(../public/fonts/Nunito-ExtraBold.ttf) format("truetype");
}
@font-face {
    font-family: $NunitoBold;
    src: local("Nunito-Bold"), url(../public/fonts/Nunito-Bold.ttf) format("truetype");
}
@font-face {
    font-family: $NunitoMedium;
    src: local("Nunito-Medium"), url(../public/fonts/Nunito-Medium.ttf) format("truetype");
}
@font-face {
    font-family: $NunitoLight;
    src: local("Nunito-Light"), url(../public/fonts/Nunito-Light.ttf) format("truetype");
}
@font-face {
    font-family: $NunitoSemiBold;
    src: local("Nunito-SemiBold"), url(../public/fonts/Nunito-SemiBold.ttf) format("truetype");
}
@font-face {
    font-family: $NunitoRegular;
    src: local("Nunito-Regular"), url(../public/fonts/Nunito-Regular.ttf) format("truetype");
}

@font-face {
    font-family: $fontBold;
    src: local("Poppins-Bold"), url(../public/fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
    font-family: $fontMedium;
    src: local("Poppins-Medium"), url(../public/fonts/Poppins-Medium.ttf) format("truetype");
}
@font-face {
    font-family: $fontRegular;
    src: local("Poppins-Regular"), url(../public/fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
    font-family: $fontLight;
    src: local("Poppins-Light"), url(../public/fonts/Poppins-Light.ttf) format("truetype");
}
@font-face {
    font-family: $fontItalic;
    src: local("Poppins-Italic"), url(../public/fonts/Poppins-Italic.ttf) format("truetype");
}

$epicColor: #7362c8;
$rareColor: #3d8ff2;
$legenColor: #ec5662;
$bgMain: #f9f5eb;
$yellow: #f7af31;
$softYellow: #fcefd4;
$bgYellow: #f9f5eb;
$red: #ef4123;
$dark: #211d3e;
$blue: #a6eaff;
$black: #212131;
$brown: #6c4b51;


body{
    background: url(./assets/images/bg-main.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    min-height: 1000px;
}
