@import "../../index.scss";

$primary: #211d3e;


.logo-monday {
    width: auto;
    height: 51px;
    object-fit: contain;

    @include media-breakpoint-down(sm) {
        width: auto;
        height: 51px;
    }
}

.logo-full-monday-control{

    margin-top: 60px;
    .logo-full-monday{
        cursor: pointer;
        width: 40%;
        height: auto;
        object-fit: contain;

        @include media-breakpoint-down(md){
            width: 60%;
        }
        
    }
}



.logo-control {
    cursor: pointer;
}

.container-xxl {
    width: 100% !important;
    max-width: 100% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    padding-top: 5px;
    padding-bottom: 5px;
}

.header-control {
    display: flex;
    width: 100%;
    margin-top: 0px !important;
    padding-top: 15px !important;
}

.nav-title {
    color: $primary;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

.nav-sub-title {
    color: $primary;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }
}



.nav-menu-title{
    margin-left: 16px;
    margin-right: 16px;
    font-size: 16px;
    font-family: 'Nunito-Bold';
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;

    &:hover{
        text-decoration: underline;
        color: $yellow;
    }
}

.btn-connect {
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    background-color: $primary !important;
    border-radius: 5rem !important;
    box-shadow: 0px 8px 16px 0px rgba(0, 38, 141, 0.102);
}

.link {
    font-size: 15px;
    font-weight: 700;
    margin-left: 16px;
    margin-right: 16px;
    width: auto;
    line-height: 24px;
    font-family: $fontRegular;
    color: $yellow !important;
    letter-spacing: 1.5px;

    &:hover {
        color: $red !important;
    }
}

.button-launch-app {
    cursor: pointer;
    height: 41px;
    border-style: none;
    padding-top: 6px;
    padding-bottom: 6px;
    width: auto;
    background: $yellow !important;
    border: 3px solid white;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
    border-radius: 8px;

    &:hover {
        border-color: white !important;
    }

    a {
        font-size: 15px;
        font-weight: 700;
        font-family: $fontRegular;
        color: black !important;
        text-decoration: none;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
    }

    @include media-breakpoint-down(lg) {
        width: 70% !important;
        font-size: 19px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.navbar-md {
    @include media-breakpoint-down(sm) {
    }
}

.monday-squad{
    margin-left: 16px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 18px;
    color: $red;
    font-family: $NunitoExtraBold;

    @include media-breakpoint-down(md){
        font-size: 17px;
        margin-left: 4px;
    }
}


.grid-control{
    width: 100%;
    z-index: 1;
    display: grid;
    grid-template-columns: 20% 80%;

    @include media-breakpoint-down(lg){
        grid-template-columns: 50% 50%;
    }

    @include media-breakpoint-down(md){
        grid-template-columns: 70% 30%;
    }
}

.btn-social{
    cursor: pointer;
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-left: 4px;
    margin-right: 4px;
}

.grid-right-control{
    margin-left: auto;
}

.btn-social-control{

    @include media-breakpoint-down(md){
        
        margin-top: 24px !important;
    }
}

.menu-img{
    cursor: pointer;
    width: 40px;
    height: auto;
    object-fit: contain;
    margin-left: 24px !important;

    @include media-breakpoint-down(md){
        width: 20px;
        height: auto;
    }
}
.text-address{
    font-size: 18px;
    font-family: $fontBold;
    color: white;
}
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 5;
    top: 0;
    right: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}
.sidenav-control{
    display: flex;
    flex-direction: column;
    padding: 8px 24px;

    .close-img{
        cursor: pointer;
        margin-left: auto;
        width: 30px;
        height: 30px;
        object-fit: contain;

        @include media-breakpoint-down(md){
            width: 20px;
            height: 20px;
        }
    }

    .text-menu{
        cursor: pointer;
        font-size: 24px;
        color: $yellow;
        font-family: $NunitoExtraBold;
        margin-top: 16px;

        @include media-breakpoint-down(sm){
            font-size: 16px;
        }
    }
    .text-menu-disable{
        margin-top:36px;
        font-size: 24px;
        color: #b6b6b6;
        font-family: $NunitoExtraBold;
        line-height: 1em;
    }

    .coming-soon{
        font-size: 18px;
        color: #b6b6b6;
        font-family: $NunitoExtraBold;
        margin-top: 16px;
    }
}
.sidenav-active {
    overflow: hidden !important;
    height: 100%;
    width: 30%;
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    @include media-breakpoint-down(lg){
        width: 50%;
    }
    @include media-breakpoint-down(md){
        width: 100%;
    }


    
  
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.navbar-sm {
    @include media-breakpoint-down(sm) {
        display: block !important;
    }
}
.navbar-expand-xl .navbar-toggler {
    display: flex !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.navbar-dark .navbar-toggler-icon {
    background-image: url("../../assets/images/menu-nav.png") !important;
}

.connect-wallet-button{
    margin-left: 24px;

    @include media-breakpoint-down(lg){
       font-size: 14px !important;
       min-width: 150px !important;
       padding-left: 4px !important;
       padding-right: 4px !important;
    }

    @include media-breakpoint-down(md){
        margin-top: 24px;
        margin-left: auto !important;
        margin-right: auto !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
        text-align: center !important;
    }

  
}


.connect-wallet-sm {
    display: none;
    margin-top: 0.5rem;

    @include media-breakpoint-down(sm) {
        display: block;
    }
}
